import React from "react";
import "./App.css";
import whaleImage from "./whale.jpg";
import whaleImageMobile from "./whale-mobile.png";

function App() {
  return (
    <div className="container">
      <img className="image-desktop" src={whaleImage} alt="Ta mère" />
      <img
        className="image-mobile"
        src={whaleImageMobile}
        alt="Ta mère (mobile)"
      />
    </div>
  );
}

export default App;
